export default [
  {
    icon: "/main/answer1.png",
    title: "💘  Как найти свою любовь",
    questionList: [
      "Каковы причины моего одиночества?",
      "Когда появится партнёр?",
      "Как обратить на себя его или её внимание?",
      "Совместимость с потенциальным партнёром",
      "Какие перспективы у наших отношений?",
    ],
  },
  {
    icon: "/main/answer2.png",
    title: "💔  Пережить расставание",
    questionList: [
      "Как перестать страдать по бывшему?",
      "Что мне поможет восстановиться?",
      "Как ментально отделиться от партнёра?",
      "С кем точно у меня получатся отношения?",
      "Как открыться новому человеку?",
    ],
  },
  {
    icon: "/main/answer4.png",
    title: "👩‍❤️‍👨  Наладить отношения в семье",
    questionList: [
      "Как вернуть былую страсть?",
      "Стоит ли нам развестись?",
      "Как улучшить отношения со свекровью?",
      "Что нам недостаёт для семейного счастья?",
      "Как наладить общение с детьми?",
    ],
  },
  {
    icon: "/main/answer3.png",
    title: "💰  Увеличить финансовый поток",
    questionList: [
      "Как повысить доход?",
      "Какие перспективы на текущей работе?",
      "Откуда взять силы для работы?",
      "Какую профессию мне выбрать?",
      "Когда мне сменить работу?",
    ],
  },

];

export const images = [
  {
    default: "/main/expert_01.png",
    srcSet: "/main/expert_01.png, /main/expert_01@2x.png 2x, /main/expert_01@3x.png 3x",
    alt: "",
    className: "questions__image questions__image--1",
  },
  {
    default: "/main/expert_02.png",
    srcSet: "/main/expert_02.png, /main/expert_02@2x.png 2x, /main/expert_02@3x.png 3x",
    alt: "",
    className: "questions__image questions__image--2",
  },
  {
    default: "/main/expert_03.png",
    srcSet: "/main/expert_03.png, /main/expert_03@2x.png 2x, /main/expert_03@3x.png 3x",
    alt: "",
    className: "questions__image questions__image--3",
  },
  {
    default: "/main/expert_04.png",
    srcSet: "/main/expert_04.png, /main/expert_04@2x.png 2x, /main/expert_04@3x.png 3x",
    alt: "",
    className: "questions__image questions__image--4",
  },
];
